import { render, staticRenderFns } from "./App.vue?vue&type=template&id=95a3f370&"
import script from "./App.vue?vue&type=script&lang=js&"
export * from "./App.vue?vue&type=script&lang=js&"
import style0 from "./App.vue?vue&type=style&index=0&id=95a3f370&prod&lang=css&"


/* normalize component */
import normalizer from "!../node_modules/.pnpm/vue-loader@15.10.2_46160d1ce2a7f868fdf8f0a6f6ef6282/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports