import Vue from 'vue'
import App from './App.vue'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import router from 'vue-router';
//import store from 'store/store.js'

Vue.use(ElementUI);

Vue.config.productionTip = false
	
new Vue({
	router,
	//store,
  render: h => h(App),
}).$mount('#app')


