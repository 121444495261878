<template>
	<div class="hello">
	<!-- 	<div class="float-left">
			<span>恭喜四级鵰荣获奖项</span>
		</div> -->
		<h2>{{ msg }}</h2>
		<div class="demo-image demo-image__preview">
			<div class="block" v-for="fit in fits" :key="fit">
				<!-- <span class="demonstration">点击查看辉煌历史</span> -->
				<el-image style="width: 400px; height: 400px" :src="url" :fit="fit" :preview-src-list="srcList"></el-image>
			</div>
		</div>
		<div class="demo-image demo-image__preview">
			<span class="demonstration">点击查看鵰王变身</span>
			<div class="block" v-for="fit2 in fits2" :key="fit2">
				<el-image style="width: 400px; height: 200px" :src="url2" :fit="fit" :preview-src-list="srcWangList"></el-image>
			</div>
		</div>
	<!-- 	<div class="float-right">
			<span>可喜可贺四级鵰</span>
		</div> -->
		<!-- <router-link to="/send1Page">go1</router-link>
		<router-view></router-view> -->
		<el-backtop >
		<div class="up_click">
			↑
		</div>
		</el-backtop>
	</div>
</template>

<script>
	
	export default {
		name: 'HelloWorld',
		props: {
			msg: String
		},
		data() {
			return {
				fits: ['cover'],
				fits2:['cover2'],
				//url: 'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg'
				url: require('../../src/img/diao.jpg'),
				url2: require('../../src/img/wang2.jpg'),
				srcList:[require('../../src/img/1.jpg'),require('../../src/img/2.jpg'),require('../../src/img/3.jpg')],
				srcWangList:[require('../../src/img/wang.jpg')],
			}
		}
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
	h3 {
		margin: 40px 0 0;
	}

	ul {
		list-style-type: none;
		padding: 0;
	}

	li {
		display: inline-block;
		margin: 0 10px;
	}

	a {
		color: #42b983;
	}

	.up_click {
		height: 100%;
		width: 100%;
		background-color: #f2f5f6;
		box-shadow: 0 0 6px rgba(0, 0, 0, .12);
		text-align: center;
		line-height: 40px;
		color: #1989fa;
	}
	
	.float-left{
		display: inline-block;
		/* float: left; */
		width: 300px;
		height: 200px;
		
	}
	
	.float-right{
		display: inline-block;
		/* float: right; */
		width: 300px;
		height: 200px;
	}
</style>